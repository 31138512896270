export const carousal = {
    "Diaper%20Bag": [
        {
            label: 'Front View',
            imgPath: require("../img/products/diaperbag/Views/2.jpg"),
        },
        {
            label: 'Back View',
            imgPath: require("../img/products/diaperbag/Views/1.jpg"),
        },
        {
            label: 'Right View',
            imgPath: require("../img/products/diaperbag/Views/3.jpg"),
        },
        {
            label: 'Left View',
            imgPath: require("../img/products/diaperbag/Views/4.jpg"),
        },
    ],

}